<template>
  <div class="tel-fixed">
    <div class="open" v-if="!show" @click="show=true"><i class="el-icon-phone-outline"></i></div>
    <div class="link font-size-20" style="margin-left:100px;" v-if="show" @click="show=false"><i class="el-icon-circle-close"></i></div>
    <div class="det" v-if="show">
      <div class="text-align-center" style="padding-top:90px">
        <div v-for="(item,index) in tel" :key="index" class="margin-bottom-10">
          <div>{{item.phone_name}}</div>
          <div class="font-size-16 color-orange bold">{{item.phone}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TelFixed',
  components: {},
  data() {
    return {
      tel:null,
      show:true
    }
  },
  methods:{
    //获取客服电话
    getTel(){
      let params= {
        ...this.$store.state.basicParams,
      }
      this.$instance.get('/user/get_service_info',{params})
      .then(res=>{
        if(res.data.code==0){
         this.$store.commit('tel',res.data.data)
         this.tel=res.data.data
        }
      })
      .catch(()=> {}); 
    },
   
  },
  created(){
    this.getTel()
  },
  mounted(){

  },
  watch: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
  .tel-fixed{
    position: fixed;
    right:0px;
    top:35%;
    z-index: 99;
    .det{
      width: 120px;
      height: 187px;
      background: url('../assets/images/tel-bg.png') no-repeat;
      margin-right: 10px;
    }
    .open{
      color: #fff;
      background: #fe5e12;
      font-size: 20px;
      padding: 5px 10px;
      border-radius: 20px 0 0 20px/20px 0 0 20px;
      transition:  all 0.5s ease 0s;
      cursor: pointer;
    }
    .open:hover{
      opacity: 0.8;
      padding-right:20px
    }
  }
</style>
