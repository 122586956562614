import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import ElementUI from 'element-ui';
import store from '@/store';
import router from '@/router';
Vue.use(VueAxios, axios)

//开发环境，接口地址
const server={
  production:"http://yinheyaoy.com:6010/api",
  development:"http://yinheyaoy.com:6010/api",
  testing:"http://47.99.59.219:6001",
}[process.env.NODE_ENV]

// 判断非本地server时，页面地址根据请求接口协议头自动判断是否转换https,自动执行函数
// !function (){
//   var reg = /^(10.99.)/,
//       index=server.indexOf("//"),
//       targetProtocol = server.substring(0,index);
//   if(window.location.hostname !== 'localhost' && !reg.test(window.location.hostname) && window.location.protocol != "file:" && window.location.protocol != targetProtocol) {
//     window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length);
//   }
// }()
function dataType(data) {
  return Object.prototype.toString.call(data).slice(8, -1).toLowerCase()
}

//axios实例
const axiosInstance = axios.create({
  baseURL: server,
  timeout: 60000,
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    let loginRes=store.state.loginRes
    if (loginRes.success) {
      config.headers['Access-Token'] = loginRes.access_token
      if(config.params){
        config.params.user_id=loginRes.user_id
      }
      if(config.data){
        if(dataType(config.data)=='string'){
          config.data+=`&user_id=${loginRes.user_id}`
        }else if(dataType(config.data)=='object'){
          config.data.user_id=loginRes.user_id
        }else if(dataType(config.data)=='formdata'){
          config.data.append( "user_id" ,loginRes.user_id);
        }
      }
    } 
    return config
  },
  error => Promise.error(error)
)

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if(response.data.code!=0){
    ElementUI.Message({message: response.data.msg||'数据获取失败，请稍后再试',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
  }
  if(response.data.code==1011||response.data.code==1012){
    if(router.currentRoute.path!="/login"){
      router.replace({
        path: '/login',
        query: { 
          status:401,
          redirect: router.currentRoute.fullPath
        }
      })
    }
  }
  return response;
}, function (error) {
  // 对响应错误做点什么
  if(error.response&&error.response.status==401){
    if(router.currentRoute.path!="/login"){
      router.replace({
        path: '/login',
        query: { 
          status:401,
          redirect: router.currentRoute.fullPath
        }
      })
    }
  }else{
     ElementUI.Message({message: '数据获取失败，请稍后再试',type:'error',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
  }
  return Promise.reject(error);
});

export {
  server,
  axiosInstance,
}