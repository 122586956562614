<template>
  <div id="app" ref="app">
    <router-view class="warp"></router-view>
    <TelFixed></TelFixed>
  </div>
</template>

<script>
import TelFixed from '@/components/TelFixed.vue'
export default {
  name: 'App',
  components: {
    TelFixed
  },
  created(){
    window.document.title=this.$store.state.systemTitle
  },
  data() {
    return {
      notice:{
        list:[],
        msg:''
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.sizeLinstener()
    })
    
    // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
    this.hashChangeLinstener()
    if(this.$store.state.loginRes.success){
      this.getNotice()
    }
  },
  methods: {
    sizeLinstener(){
        this.$erd.listenTo({
            strategy: "scroll", //<- For ultra performance.
            callOnAdd: false,
            debug: false
        },this.$refs.app, ()=>{
            this.$root.docHeight=document.documentElement.clientHeight
            this.$root.docWidth=document.documentElement.clientWidth
        });
    },
    hashChangeLinstener(){
      window.addEventListener('hashchange', () => {
        let currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }, false)
    },
    getNotice(){
      this.loading=true;
      this.$instance.get('/main/get_remind_list',{
        params:{
          ...this.$store.state.basicParams,
        }
      })
      .then(res=>{
        if(res.data.code==0){
          this.notice.list=res.data.data.list
          this.notice.msg=res.data.data.msg
          //通知弹窗提示
          if(this.notice.length){
            let message=''
            for (let item of this.notice.list) {
                message+=`<div class="margin-top-5"style="width:260px">${item}</div>`
            }
            message+=`<div class="margin-top-5"style="width:260px">${this.notice.msg}</div>`
            this.$notify({
              title: '有新提醒',
              type:'warning',
              position:'top-right',
              duration:0,
              dangerouslyUseHTMLString: true,
              message: message,
              offset:0,
            });
          }
        }
      })
    },
  },
  watch: {
   '$store.state.loginRes':{
    handler:function(v){
      v.success&&this.getNotice()
    },
    deep:true
   },
  }
}
</script>
<style lang="scss" scoped>
  .warp{
    width: 100%;
    min-width: 1200px;
    box-sizing: border-box;
    
  }
</style>
<style lang="scss">
  @import './assets/css/common.scss';
</style>