import Vue from "vue";
import Vuex from 'vuex'
Vue.use(Vuex)
//去重对象数组函数
//store
const store = new Vuex.Store({
    state: {
        systemTitle:'银河药业网上商城，网上买药，药品批发，购药网站',
        version:'1.0',
        basicParams:{
            device_type:2
        },//接口公共参数
        loginRes:{},//用户登录返回的信息
        currCustomer:{},
        gwcNum:0,//购物车角标
        tel:null,//客服电话
        submitOrderParams:null
    },
    getters: {
        isSpecUser(state){
            return state.loginRes.role_id==2||state.loginRes.role_id==5?true:false
        },
        isYWY(state){
            return state.loginRes.role_id==2?true:false
        },
        isHZ(state){
            return state.loginRes.role_id==5?true:false
        }
    },
    mutations: {
        //state数据初始化
        stateInit(state){
            state.loginRes={}//用户登录返回的信息
            state.basicParams= {device_type:2}
            state.currCustomer={}
        },
        //用户信息
        loginRes (state,newval) {
            state.loginRes=newval
        },
        //接口公共参数
        basicParams (state,newval) {
            state.basicParams=newval
        },
        //选中的客户
        currCustomer (state,newval) {
            state.currCustomer=newval
        },
        //客服电话
        tel (state,newval) {
            state.tel=newval
        },
        //结算参数
        submitOrderParams (state,newval) {
            state.submitOrderParams=newval
        },
        //gwcNum
        gwcNum (state,newval) {
            state.gwcNum=newval
        },
    },
    actions: {
        gwcNum(store){
           
            Vue.prototype.$instance.get('/cart/get_cart_list',{
                params: {
                    ...store.state.basicParams,
                    page:1,
                    belonger_user_id:store.state.currCustomer.user_id||0,
                },
            })
            .then(res=>{
                if(res.data.code==0){
                    store.commit('gwcNum',res.data.data.total)
                }else{
                    store.commit('gwcNum',0)
                }
            })
            .catch(()=> {store.commit('gwcNum',0)}); 
            
        },
    }
})

export default store