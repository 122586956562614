import Vue from 'vue'
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ElementUI from 'element-ui';//element组件注册
import elementResizeDetectorMaker from 'element-resize-detector';//导入监听dom尺寸变化的侦听器
import router from '@/router'
import store from '@/store'
import {server,axiosInstance} from "@/axios"//axios全局配置
import qs from 'qs'
import App from '@/App.vue'
import * as echarts from 'echarts'; //导入echarts
import defaultChartOption from '@/common/defaultChartOption';//导入echart默认配置项参数

import VueEsign from 'vue-esign';//手写签字插件


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueEsign);

import 'font-awesome/css/font-awesome.min.css';//导入fontAwesome图标库
import '@/assets/css/element-variables.scss';//element-ui主题色

import "@/common/filter.js"
//通用工具类js方法及filter过滤器


//vue原型挂载
Vue.prototype.$server=server//请求地址
Vue.prototype.$instance=axiosInstance//axios全局实例
Vue.prototype.$qs = qs;//参数序列化
Vue.prototype.$erd = elementResizeDetectorMaker();//监听dom尺寸变化方法
Vue.prototype.$echarts = echarts;//echarts实例
Vue.prototype.$defaultChartOption = defaultChartOption;//echarts默认options

//VUE实例
new Vue({
  router,
  store,
  data:{
    docHeight:document.documentElement.clientHeight,
    docWidth:document.documentElement.clientWidth
  },
  methods:{
    //路由跳转方法
    jump: function({path,query={},blank=false,relace=false,}){
      if(blank){
        let routeData = this.$router.resolve({ 
          path,
          query
        });
        window.open(routeData.href, '_blank');   
      }else{
        if(path == this.$route.path||relace){
          let routeData = this.$router.resolve({ 
            path,
            query
          });
          this.$router.replace({
            path: '/redirect',
            query: {path:routeData.href.substring (1)}
          })
        }else {
          this.$router.push({
            path,
            query
          })
        }
      }
    },
    tips(){
      this.$message({message: '此功能敬请期待',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
    },
    //滚动加载更多
    scrollLoad({dom=null,allow=true,offset=0,callBack=()=>{}}){
      if(allow&&dom&&dom.getBoundingClientRect().bottom<=(window.innerHeight+offset)){
        callBack() 
      }
    }
  },
  render: h => h(App),
}).$mount('#app')